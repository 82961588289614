import React, { useEffect, useRef, useState } from "react";
import Image from "../../components/image";
import SubscriptionWizard from "../../components/subscription-wizard/subscription-wizard";
import FullPageLoader from "../../containers/full-page-loader";
import Constants from "./constants";
import "./home-page.scss";
import InitialScript from "./initial-script";

const HomePage = () => {
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    InitialScript();
  }, []);

  const [headerClassName, setHeaderClassName] = useState("");

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "shrinkHeader" && window.pageYOffset >= 100) {
      setHeaderClassName("shrinkHeader");
    } else if (headerClassName === "shrinkHeader" && window.pageYOffset < 100) {
      setHeaderClassName("");
    }
  };

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  const handleNavClick = (event, targetId) => {
    event.preventDefault(); // Prevent default anchor link behavior
    // remove the hash from the url
    window.history.replaceState(null, null, " ");
    const element = document.getElementById(targetId);

    if (element) {
      // Check if the user is on a mobile device
      const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed

      // Set offset dynamically based on device type
      const headerOffset = isMobile ? 150 : 70; // Smaller offset for mobile
      const elementPosition = element.offsetTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  return (
    <>
      <div className="homePage">
        {/* ======= Header ======= */}
        <header
          id="header"
          className={"d-flex align-items-center " + headerClassName}
        >
          <FullPageLoader></FullPageLoader>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="logo me-auto">
                  {/* <Image
                      // src="assets/img/hero-img.png"
                      src="assets/img/Brain Gears.jpg"
                      className="img-fluid"
                      alt
                    />
                  <h1>
                    <a href="index.html">My Daily Dose</a>
                  </h1> */}
                  {/* Uncomment below if you prefer to use an image logo */}
                  <a href="index.html">
                    <h3>My Daily Dose of Goodness</h3>
                    <span>Daily Texts of Stoic Wisdom for a Modern World</span>
                  </a>
                </div>
              </div>

              <div className="col-lg-7">
                <nav id="navbar" className="navbar order-last order-lg-0">
                  <ul>
                    <li>
                      <a
                        className="nav-link "
                        href="#work"
                        onClick={(e) => handleNavClick(e, "work")}
                      >
                        How it Works
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        href="#subscription"
                        onClick={(e) => handleNavClick(e, "subscription")}
                      >
                        Why Subscribe
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        href="#sample-text"
                        onClick={(e) => handleNavClick(e, "sample-text")}
                      >
                        Sample Texts
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        href="#terms"
                        onClick={(e) => handleNavClick(e, "terms")}
                      >
                        Terms of Service
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        href="#trial"
                        onClick={(e) => handleNavClick(e, "trial")}
                      >
                        7-day No Charge Preview
                      </a>
                    </li>
                  </ul>
                  {/* <i className="bi bi-list mobile-nav-toggle" /> */}
                </nav>
              </div>
            </div>
            {/* .navbar */}
          </div>
        </header>
        <div
          className="topButton"
          onClick={(e) => {
            window.scrollTo(0, 0);
          }}
        >
          <i className="fa-solid fa-arrow-up"></i>
        </div>
        {/* End Header */}
        {/* ======= Hero Section ======= */}
        {/* End Hero */}
        <main id="main">
          {/* ======= About Section ======= */}
          <section id="about" className="about">
            <div className="container">
              <div className="inner">
                <div className="left" data-aos="zoom-in">
                  {/* <SubscriptionWizard gotoSampleMessages={executeScroll} /> */}
                  <div className="bigHeading">
                    <h1>
                      {" "}
                      Welcome to My Daily Dose Text.{" "}
                      <span>We're Glad You Found Us</span>.
                    </h1>
                  </div>

                  <div className="onlyForMobile mb-3 d-md-block d-lg-none">
                    {" "}
                    {/* this image is in two place. check home-page.js */}
                    <img
                      src={require("../../assets/img/sopher2.jpg")}
                      // src="https://im4.ezgif.com/tmp/ezgif-4-89922e04e5.png"
                      className="img-fluid"
                      alt="My Daily Dose Text Daily Stoic Wisdom To Help You Grow"
                    />
                  </div>

                  {/* <div className="smallHeading">The two guiding principles of this texting platform are Anonymity and Doing Good.</div> */}

                  <p>
                    In our fast-paced modern society, perhaps more than ever, we
                    can benefit from the time-tested wisdom of ethical behavior.
                    Here you can subscribe to receive a daily text of
                    philosophical wisdom and motivation from notable Stoics and
                    other contemporary philosophers. Each daily text imparts
                    knowledge, insight, and encouragement to do good, to be
                    good, to be better, and make a difference in the lives of
                    those in your circle of influence, whether for yourself,
                    your family, your children, or in your profession.
                  </p>

                  <p className="smallHeading">
                    Consider a 7-day No Charge Preview , by clicking on{" "}
                    <a
                      href="#subscription"
                      className="link"
                      onClick={(e) => handleNavClick(e, "subscription")}
                    >
                      Why Subscribe
                    </a>
                    .
                  </p>

                  <p>
                    <span className="highlight">
                      Subscriber Support{" "}
                      <a href="mailto:joel@MDDtext.com" className="link">
                        joel@MDDtext.com
                      </a>
                    </span>
                  </p>

                  <p>
                    Many thanks to the several companies and their talented
                    technical departments that made it possible to bring this
                    content to subscribers. I’m grateful for the technology that
                    allows us to disseminate so much information, so quickly, to
                    so many people. The Stoics couldn’t have imagined doing what
                    we are able to do with today’s technology. Let’s honor them
                    by using our resources and technology wisely to create more
                    goodness and a better world.
                  </p>
                </div>
                <div className="right d-none d-lg-block">
                  {" "}
                  {/* this image is in two place. check subscription-wizard.js */}
                  <img
                    src={require("../../assets/img/sopher2.jpg")}
                    // src="https://im4.ezgif.com/tmp/ezgif-4-89922e04e5.png"
                    className="img-fluid"
                    alt
                  />
                </div>
              </div>

              {/* <div className="smallHeading mt-4">If these principles speak to you, I invite you to browse the website, subscribe, enjoy your daily texts, and support the project.</div>
              <p>Many thanks to the several companies and their talented technical departments that made it possible to bring this to the subscribers. am grateful for the technology that allows us to disseminate so much information, so quickly, to so many people. The Stoics couldn't have imagined doing what we are able to do with today's technology. Let's honor them by using our resources and technology wisely to create more goodness and a better world.</p> */}
            </div>
          </section>

          {/* ======= How it works Section ======= */}
          <section id="work" className="work py-0">
            <div className="container">
              <div className="inner">
                <div className="row">
                  <div
                    className="col-lg-12 d-flex flex-column justify-contents-center"
                    data-aos="fade-left"
                  >
                    <h2 className="sectionHeading">How It Works</h2>
                    <p>
                      Click on the 7-Day No Charge Preview button and follow the
                      prompts to set up your temporary membership. Receive your
                      texts for 7 days at no charge. If you wish to continue
                      getting your texts, do nothing and your subscription
                      becomes active and you will be charged the subscription
                      amount of $6.19 per month (20 cents a day) until you
                      cancel your subscription.{" "}
                    </p>

                    <p className="mb-0">
                      Cancel your subscription anytime by replying STOP to any
                      of the texts. If you cancel during the no charge preview,
                      your card will not be charged, and no further texts will
                      be sent to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ======= Why Subscribe Section ======= */}
          <section id="subscription" className="subscription">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="sectionHeading">Why Subscribe</div>
                  <ul className="ps-0 list-unstyled reason-subscription position-relative">
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">01</span>
                      <p className="mb-0 ps-3">
                        Enjoy automatic reception of the philosophical daily
                        texts. You won’t even have to think about it. There’s no
                        website to visit, no app to open or email to search
                        through. The text will drop right into your phone every
                        day automatically and notify you.
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">02</span>
                      <p className="mb-0 ps-3">
                        Each text will be a fresh take on valuable food for
                        thought, encouragement to do good, improve your life,
                        and edify others.
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">03</span>
                      <p className="mb-0 ps-3">
                        Use each text for a quick read or make it part of your
                        daily meditative routine. You can even save the texts to
                        revisit or share. They make great conversation starters
                        or take off points for personal research and study.
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">04</span>
                      <p className="mb-0 ps-3">
                        These principles are foundational in teaching children
                        how to effectively navigate their modern environment and
                        challenges. You can share valuable conversations with
                        your children using these daily topics.
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">05</span>
                      <p className="mb-0 ps-3">
                        Subject matters covered in the daily texts can be a
                        resource in your professional setting for training and
                        development or motivating your office personnel.{" "}
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">06</span>
                      <p className="mb-0 ps-3">
                        All the research, prep work, and writing are done for
                        you and delivered automatically.
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">07</span>
                      <p className="mb-0 ps-3">
                        This might be the best investment value you will ever
                        make at just 20 cents a day, $6.19 per month. Think
                        about how much you spend each month on just snacks or
                        coffee.
                      </p>
                    </li>
                    <li className="mb-4 d-flex align-items-center">
                      <span className="">08</span>
                      <p className="mb-0 ps-3">
                        You get to enjoy 7 days of free texts with no
                        obligation. It’s like a peek into the subscription
                        without subscribing.
                      </p>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="">09</span>
                      <p className="mb-0 ps-3">
                        Perhaps best of all, you may open a whole new world of
                        philosophical adventure.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* ======= Preview of Actual Texts Section ======= */}
          {/* <section id="testimonials" className="testimonials" ref={myRef}>
            <div className="container">
              <div className="sectionHeading center"> Preview of Actual Texts </div>
              <div
                className="testimonials-slider swiper"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="swiper-wrapper">
                  {Constants.map((message, messageIndex) => (
                    <div className="swiper-slide" key={messageIndex}>
                      <div className="testimonial-item">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          {message.text}
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                        <Image src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt />
                        <h3>{message.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="swiper-pagination" />
              </div>
            </div>
          </section> */}

          {/* ======= Sample Texts Section ======= */}
          <section id="sample-text" className="sample-text">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div
                  className="col-lg-12"
                  data-aos="fade-left"
                >
                  <h2>Sample Texts</h2>
                  <br />
                </div> */}
                <div className="col-12">
                  <div className="sectionHeading">Sample Texts</div>
                </div>
                <div className="col-md-12">
                  {/* <div className="card p-4 border-0"> */}
                  {/* <div className="textCard">
                      <span>SAMPLE TEXT #1</span>
                      <h4 className="mt-1 fw-semibold">NO STAIRS </h4>
                      <p className="">We’re not referring to the stairs of ascension, rather the lack of any perceptible support system as you take that courageous leap of faith towards your dreams and aspirations. The fear of not being able to feel a firm foundation beneath your feet as you venture out is the filter that allows only the fearless to proceed. In a segment of the fabled, “The Golden Key”, by George MacDonald, Scottish author (1824-1905), The Old Man of the Earth removes a large stone from the floor only to expose what appears to be a bottomless pit and declares, “That is the Way.” “But there are no stairs!”, the student replies. The Old Man of the Earth responds, “You must throw yourself in. There is no other way.” In that moment, one is either filtered through or filtered out. </p>
                    </div> */}
                  <div className="textCard">
                    <span>SAMPLE TEXT #72</span>
                    <h4 className="mt-1 fw-semibold">
                      IT’S ALL IN THE WANTING
                    </h4>
                    <p className="">
                      Unless we are being held against our will or forced to do
                      something under duress, we mostly are free to exercise our
                      agency – the freedom to determine our actions. Yet in many
                      cases we choose to do that which is not in our best
                      interest or others’ best interest. Then we ask ourselves,
                      why do we keep repeating the same harmful behaviors? I’m
                      trying to do better. I’m trying to resist the temptation
                      to go right to anger, to not lash out, to not judge, to
                      not be jealous or lust after another’s partner. I posit
                      that it’s because we actually want to do those things.
                      There must be a payoff from behaving poorly. What’s the
                      payoff? You must go search deep inside and find what you
                      are getting out of bad behavior and decide what to do
                      about it. I’ve found that in the end analysis, people do
                      exactly what they <b>WANT</b> to do, both helpful and
                      harmful. It’s all in the wanting. What do you really want
                      to do and how to behave, and why? Marcus Arelius put it
                      this way in his Meditations, Book 9 entry 40,...{" "}
                      <b>
                        <i>
                          Start praying like this and you’ll see. Not “some way
                          to sleep with her” – but a way to stop wanting to. Not
                          “some way to get rid of him” – but a way to stop
                          trying. Not “some way to save my child” – but a way to
                          lose your fear. Redirect your prayers (thoughts,
                          wants, desires, meditations) like that and see what
                          happens.
                        </i>
                      </b>{" "}
                      This self-directed, voluntary redirecting of wants and
                      desires converts one from a helpless victim to the master
                      of one’s destiny.
                    </p>
                  </div>
                  <div className="textCard">
                    <span>SAMPLE TEXT #2</span>
                    <h4 className="mt-1 fw-semibold">
                      OUR OLD FAMILIAR FRIEND{" "}
                    </h4>
                    <p className="">
                      {" "}
                      It’s not what you may think. It’s not the friend that we
                      grew up with all through school. But it attaches itself to
                      everyone at the moment of birth and most mistakenly try
                      their entire lives to avoid it, minimize it, ignore it,
                      and even attempt to escape from it, all in vain. Fear is
                      our Old Familiar Friend. It warns us about danger and
                      harm, and it motivates us toward success. But those
                      positive effects are only possible when we embrace fear
                      and develop a healthy relationship with it. Under that
                      light, fear becomes a valuable ally, a guidepost
                      suggesting which way to go for our best good. Just what an
                      Old Familiar Friend is for. This must be why the Spartans
                      built temples to Fear. They understood their relationship
                      to fear through embracing it as a natural human phenomenon
                      then putting it to its best possible use - overcoming it
                      through appropriate action.{" "}
                    </p>
                  </div>
                  <div className="textCard">
                    <span>STOIC TEXT #18</span>
                    <h4 className="mt-1 fw-semibold">THE TRICYCLE EFFECT</h4>
                    <p>
                      When “it” hits the fan, on a global scale like Covid or
                      regional wars, or discord and division during an election,
                      or a local disaster, or a personal tragedy, or even having
                      to deal with the daily pressure to perform at work (all of
                      which can be happening simultaneously – WOW !!), an
                      autonomic response kicks in for the mind to seek calm,
                      order, and equanimity. This automatic survival technique
                      is built into our DNA. Remember what it feels like when
                      riding your tricycle and going too fast when turning and
                      your back wheel comes off the ground and a crash is
                      imminent? You restore balance intuitively. Living the
                      time-tested moral principles of Stoicism are the firm
                      foundation that automatically restores equanimity and
                      balance to an otherwise chaotic modern existence. When the
                      storm rages and the winds howl and the rain is relentless,
                      our Stoic resolve is always greater than what only appears
                      to be impending doom.
                    </p>
                  </div>
                  <div className="textCard">
                    <span>STOIC TEXT #24</span>
                    <h4 className="mt-1 fw-semibold">WHERE ARE THEY? </h4>
                    <p className="">
                      The Stoic gets a bad rap because of the modern definition
                      of the word itself. We think in pictures and the very word
                      ‘stoic’ conjures up images of this old guy with a long
                      beard, curly hair, made out of stone, literally and
                      figuratively. I wouldn’t know exactly how they were then.
                      I wasn’t there. But it certainly is not an accurate
                      description of a stoic person in today’s modern society.
                      Stoics are all around us, male and female alike. Just
                      check out the various online Stoicism Groups. You see,
                      stoics don’t wear their identity on their sleeves.
                      Otherwise, they wouldn’t be stoic, would they? They are
                      the spiritual and mental giants that are mostly invisible
                      because they don’t need or seek external recognition to
                      validate their worth. You might be in the presence of
                      stoics if they do great things and are unmoved by praise.
                      Look around you. Look inside. Are you one of the stoics,
                      the thinkers, the doers? Could you aspire to be one?
                    </p>
                  </div>
                  <div className="textCard">
                    <span>STOIC TEXT #30</span>
                    <h4 className="mt-1 fw-semibold">DANGEROUS AFFLUENCE</h4>
                    <p>
                      We spend a lifetime making everything easier, faster,
                      bigger, better, prettier. And true, in an affluent society
                      we do have the resources to do just that, create ease and
                      comfort. But like most things, there is a downside. That
                      is, the lifestyle we try so very hard to acquire creates
                      generations of “softies”, never left wanting for any
                      necessity, and accumulating loads of “things” that we
                      store in the garage after their newness wears off.
                      Furthermore, that which we do acquire never seems to be
                      enough. More, more, more, is the cry of the unquenchable
                      fire of affluence. So, what’s so bad about that, you ask?
                      Here’s what. You become ill-prepared to cope when things
                      take a downturn with your personal life, your family, your
                      community, your country, and the world. Two obvious
                      courses of action should be kept in mind as you press for
                      more and more. One, make do with what you have while
                      honestly scrutinizing all future acquisitions. This is
                      done with the simple questions, WHY am I doing this? Why
                      do I have this? Why am I buying this? And two, make
                      available a portion of your resources to edify society
                      commensurate with your level of affluence.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ======= Terms & Condition Section ======= */}
          <section id="terms" className="pt-4">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 d-flex flex-column justify-contents-center"
                  data-aos="fade-left"
                >
                  <h2 className="sectionHeading">Terms of Service</h2>
                  <br />
                  <ol>
                    <li className="mb-3">
                      <p className="mb-0">
                        This is a subscription-based service that you
                        voluntarily opt into by submitting your personal
                        subscription information.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        The 7-day No Charge Preview on this website is the only
                        way to subscribe.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        You must use a valid debit/credit card to subscribe.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        The subscription, should you choose to continue beyond
                        the 7-day No Charge Preview , is an automatic, monthly
                        recurring charge of $6.19.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        You will receive daily texts as long as your card is
                        valid at renewal, and you have not opted out.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        Your card will not be charged during the 7-day No Charge
                        Preview but will automatically start being charged the
                        monthly subscription after the 7-day No Charge Preview
                        if you do not cancel during the trial period.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        You may cancel anytime by replying to any of the texts
                        you receive with the word <b>STOP</b>.
                      </p>
                    </li>
                    {/* <li className="mb-3">
                      <p className="mb-0">If you cancel your subscription mid renewal cycle, you will continue to receive texts through the end of that ending cycle.  But you will not be charged again after you reply STOP to any of the texts.</p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">There are no subscription refunds available either partial or full.   Once your renewal fee is collected you will receive daily texts up to the renewal date. </p>
                    </li> */}
                    <li className="mb-3">
                      <p className="mb-0">
                        Your subscription amount will never increase. If there
                        is an increase in the subscription amount, the increase
                        will affect only those that subscribe after the
                        increase. All previous subscribers will remain at their
                        original subscription rate.{" "}
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        If you cancel your subscription and want to resubscribe,
                        you can do so at the, then current subscription rate.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        By agreeing to these Terms of Service for My Daily Dose
                        above, you also understand and agree to accept that the
                        texts you receive are not phycological, mental health,
                        or medical advice. You also agree that the texts are not
                        intended to treat any illness, medical issues of any
                        kind, or mental illness. And furthermore, you agree to
                        hold harmless, and agree to NOT file any suit or legal
                        action of any kind against My Daily Dose or MDDtext.com,
                        or MDDtext.com texting subscription service, or any
                        entity associated with said companies or services.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        You may reach customer care via email at{" "}
                        <a className="link" href="mailto:joel@MDDtext.com">
                          joel@MDDtext.com
                        </a>
                        .
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          {/* ======= 7 Days Free Trial Section ======= */}
          <section id="trial" className="work pt-0">
            <div className="container pt-0">
              <div className="row">
                <div
                  className="col-lg-12 d-flex flex-column justify-contents-center"
                  data-aos="fade-left"
                >
                  <div className="sectionHeading">7-day No Charge Preview</div>
                  <br />
                  <ul>
                    <li className="mb-3">
                      <p className="mb-0">
                        Clicking on any 7-day No Charge Preview link on this
                        site will take you to the subscription uptake process.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        This is a no obligation offer. There will be no charge
                        to your credit/debit card until after the free preview.
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0">
                        The preview is completely free. If you chose to not
                        receive texts after the preview period and not be
                        charged for the first month of texts, you must cancel
                        during the 7-day No Charge Preview.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-5">
                  <button
                    className="btn-get-started goButton"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    7-day No Charge Preview &nbsp;{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </section>
          {/* End Testimonials Section */}
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <div id="footer">&copy; 2024 My Daily Dose. All rights reserved.</div>
        {/* End Footer */}
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                7-day No Charge Preview
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <SubscriptionWizard />
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
